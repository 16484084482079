import React, {Component} from "react";
import {
    Paper,
    Box,
    Card, CardHeader, CardContent,
    ToggleButtonGroup,
    ToggleButton, Typography, Stack
} from '@mui/material';
import DLG_ConfigAppSetup from "../Controls/Setup/Application/DLG_ConfigAppSetup";
import DLG_CalcManager from "../Controls/Setup/Calculator/DLG_CalcManager";
import DLG_NavBar from "../DLG_NavBar";

class MainConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSetup: "calc" // default to CALCULATOR
        };
        this.handleChange = this.handleChange.bind(this);
    }

    appSetup() {
        return (
            <Card sx={{
                width: '90%',
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CardHeader>

                </CardHeader>
                <CardContent>
                    <DLG_ConfigAppSetup/>
                </CardContent>
            </Card>
        );
    }

    calcSetup() {
        return (
            <Card sx={{
                width: '90%',
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CardHeader title="Calculator Setup"/>
                <CardContent>
                    <DLG_CalcManager/>
                </CardContent>
            </Card>
        );
    }

    handleChange(event, newSelection) {
        if (newSelection) {
            this.setState({
                selectedSetup: newSelection
            });
        }
    }

    render() {
        return (
            <Box p={7}>
                <Typography sx={{
                    margin: '20px',   // Margins around the card
                    color: 'rgb(35,141,157)'
                }}
                >Select configuration option</Typography><ToggleButtonGroup
                value={this.state.selectedSetup}
                exclusive
                onChange={this.handleChange}
                color="primary"
                aria-label="Platform"
            >
                <ToggleButton value="calc">CALCULATOR</ToggleButton>
                <ToggleButton value="app">APPLICATION</ToggleButton>
            </ToggleButtonGroup>

                {/* Conditionally render based on state */}
                {this.state.selectedSetup === "calc" ? this.calcSetup() : this.appSetup()}
                <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={0}>
                    <DLG_NavBar highlight={"Setup"}/>
                </Paper>
            </Box>
        );
    }
}

export default MainConfig;
