import {Box, Typography, IconButton, ToggleButtonGroup, ToggleButton} from '@mui/material';
import React from "react";
import CTRL_CalcAddElement from "./CTRL_CalcAddElement";
import CTRL_CalcListAssets from "./CTRL_CalcListAssets";
import CTRL_CalcListCategories from "./CTRL_CalcListCategories";
import CTRL_CalcListDesigns from "./CTRL_CalcListDesigns";

class DLG_CalcManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSetup: "category" // default to CALCULATOR
        };

        this.handleChange = this.handleChange.bind(this);
        this.displayList = this.displayList.bind(this);
        this.handleOrderChange = this.handleOrderChange.bind(this);
        this.updateElements = this.updateElements.bind(this);
    }

    componentDidMount() {
        fetch('/api/calcconfig/getasset') // replace with your API URL
            .then((response) => response.json())
            .then((data) => {
                this.setState({assets: data})
            })
            .catch((error) => console.error('Error:', error));

        fetch('/api/calcconfig/getcategory') // replace with your API URL
            .then((response) => response.json())
            .then(data => {
                const updatedData = data.category.map((item, index) => ({
                    ...item,
                    order: item.order !== undefined ? item.order : index
                }));
                this.setState({categories: updatedData});
            })
            .catch((error) => console.error('Error:', error));

        fetch('/api/calcconfig/getmenuset') // replace with your API URL
            .then((response) => response.json())
            .then((data) => {
                this.setState({menuset: data})
            })
            .catch((error) => console.error('Error:', error));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    async updateElements(categories) {
        const response = await fetch("/api/calcconfig/storecategory", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(categories),
        });
        //const {file_id} = await response.json();
    }

    handleChange(event, newSelection) {
        if (newSelection) {
            this.setState({
                selectedSetup: newSelection
            });
        }
    }

    handleOrderChange(newOrder) {
        this.setState({categories: newOrder});
        // Or any other logic you need to execute
        console.log("UPDATE", newOrder)
        this.updateElements(newOrder)
    }

    displayList(type) {
        switch (type) {
            case("category"):
                return <CTRL_CalcListCategories categories={this.state.categories}
                                                onOrderChange={this.handleOrderChange}/>
            case("design"):
                return <CTRL_CalcListDesigns designs={this.state.design}/>
            case("block"):
                return ''
            case("menuelement"):
                return <CTRL_CalcListAssets assets={this.state.assets}/>
            default:
                return <CTRL_CalcListAssets assets={this.state.assets}/>
        }
    }

    render() {
        return (
            <Box>
                <Typography sx={{
                    margin: '20px',   // Margins around the card
                    color: 'rgb(35,141,157)'
                }}
                >
                    Select configuration option
                </Typography>
                <ToggleButtonGroup
                    value={this.state.selectedSetup}
                    exclusive
                    onChange={this.handleChange}
                    color="primary"
                    aria-label="Platform"
                >
                    <ToggleButton value="category">Manage Categories</ToggleButton>
                    <ToggleButton value="design">Manage Designs</ToggleButton>
                    <ToggleButton value="block">Manage Blocks</ToggleButton>
                    <ToggleButton value="asset">Manage Assets</ToggleButton>
                    <ToggleButton value="menuasset">Manage Menu Assets</ToggleButton>
                </ToggleButtonGroup>
                <CTRL_CalcAddElement/>
                {this.displayList(this.state.selectedSetup)}
            </Box>
        )
    }
}

export default DLG_CalcManager;