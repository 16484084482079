import {
    Button,
    Card,
    CardActions,
    CardContent,
    FormGroup,
    Grid,
    Stack,
    Typography,
    TextField,
    MenuItem, Select, Divider, CardHeader
} from "@mui/material";
import CalcItem from "./CTRL_CalcItem";
import CalcItemMenu from "./CTRL_CalcItemMenu";
import React from "react";
import PaidSelect from "../CTRL_PaidSelect";
import {GridCName, GridCValue, GridCEName, GridCEValue, GridC} from "../STL_Item";


class CalcSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            segmenttotal: [],
            total: 0
        }
    }

    componentDidMount() {
        //console.log("PriceList", this.props.priceList)
    }

    calculateTotal = (priceList) => {
        let total = 0;

        Object.values(priceList).forEach(item => {
            const assetprices = [];

            if (item && item.assetlist) {
                Object.values(item.assetlist).forEach(asset => {
                    if (asset && asset.finalprice) {
                        assetprices.push(asset.finalprice);
                    }
                });
            }

            const _finalprice = item.designlist?.finalprice || 0;
            const segmentprice = assetprices.reduce((total, value) => total + value, 0) + _finalprice;
            total += segmentprice;
        });

        return total;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("PRICELIST", this.props.priceList)
        if (prevProps.priceList !== this.props.priceList) {
            const assetprices = [];

            Object.values(this.props.priceList).forEach(item => {
                if (item && item.assetlist) {
                    Object.values(item.assetlist).forEach(asset => {
                        if (asset && asset.finalprice) {
                            assetprices.push(asset.finalprice);
                        }
                    });
                }
            });

            if (prevProps.priceList !== this.props.priceList) {
                const _total = this.calculateTotal(this.props.priceList);

                if (prevState.total !== _total) {
                    this.setState({total: _total});
                }
            }
        }
    }


    render() {
        const jsonLines = JSON.stringify(this.props.priceList, null, 2).split("\n");

        const innerFrame = (assetlist) => {
            if (!assetlist) return null;

            const assets = Object.keys(assetlist).map((key, index) => (
                <Grid container key={key + 'f_' + index}>
                    <GridCEName item xs={6}>{assetlist[key].name}:</GridCEName>
                    <GridCEValue item xs={6}>{'£' + assetlist[key].finalprice}</GridCEValue>
                </Grid>
            ))
            return <>{assets}</>
        }

        const CalculationCard = ({priceList}) => {
            const priceElements = Object.entries(priceList).map(([key, value], index) => {

                const assetprices = [];
                Object.keys(value.assetlist || {}).forEach((key) => {
                    assetprices.push(value.assetlist[key].finalprice || 0);
                });

                const _finalprice = value.designlist?.finalprice || 0
                const segmentprice = assetprices.reduce((total, value) => total + value, 0) + _finalprice;


                return (
                    <Card key={key + '_' + index}>
                        <CardContent>
                            <Grid container>
                                <GridC item xs={12}>{value.name}</GridC>
                                <GridCName item xs={6}>Scaffold type:</GridCName>
                                <GridCValue item xs={6}>{value.designlist?.categoryname || ''}</GridCValue>
                                <GridCName item xs={6}>Design:</GridCName>
                                <GridCValue item xs={6}>{value.designlist?.designname || ''}</GridCValue>
                                {value.designlist?.comment ?
                                    <Grid container xs={12}>
                                        <GridC item xs={12}>Custom adjustment added</GridC>
                                        <GridCName item xs={6}>{value.designlist?.comment || ""}</GridCName>
                                        <GridCValue item xs={6}>{value.designlist?.margin || ""}</GridCValue>
                                    </Grid> : ""}
                                <GridCName item xs={6}>Price:</GridCName>
                                <GridCValue item xs={6}>{value.designlist?.finalprice || 0}</GridCValue>
                                <GridC container>
                                    <GridC item xs={12}>Scaffold Elements</GridC>
                                    {innerFrame(value.assetlist)}
                                </GridC>
                                <GridC container>
                                    <Grid item xs={6} alignContent="right">
                                        <Typography variant="h5" align='right'>Segment price:</Typography>
                                    </Grid>
                                    <Grid item xs={6} align="left">
                                        <Typography variant="h5" pl={1}>£ {segmentprice}</Typography>
                                    </Grid>
                                </GridC>
                            </Grid>
                        </CardContent>
                    </Card>
                );
            });
            return (
                <Stack spacing={2}>
                    {priceElements}
                    <Card>
                        <Typography variant='h4'>Total: £{this.state.total}</Typography>
                    </Card>
                </Stack>
            )
        }

        if (!this.props.priceList) {
            return <div>Loading...</div>;
        }
        return (
            <Stack spacing={1} pr={1} mt={2}>
                <Card>
                    <CardContent>
                        <Typography variant='h4'>Summary</Typography>
                    </CardContent>
                </Card>
                <CalculationCard priceList={this.props.priceList} total={20}/>
                <div style={{
                    color: '#238d9d',
                    fontFamily: 'monospace',
                    whiteSpace: 'pre-wrap',
                    padding: '15px',
                    borderRadius: '5px'
                }}>
                </div>
            </Stack>
        )
    }
}

export default CalcSummary;