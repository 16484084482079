import {Button, ButtonGroup} from '@mui/material';
import React from "react";

export default function DLG_NavBar(props){
    const [selected, setSelected] = React.useState(window.location.pathname.split('/')[1].toUpperCase());
    const go = (event, c) => {
        event.preventDefault();
        window.location.assign(event.target.value);
    };
    const getSelected = () => {
        setSelected = window.location.pathname.split('/')[0];
    }
    return (
            <ButtonGroup variant="contained" aria-label="outlined primary button group" fullWidth>
                <Button style={{ backgroundColor: props.highlight == "Hires"? 'red': 'color'}} value="hires" variant="contained" onClick={go}>Hires</Button>
                <Button style={{ backgroundColor: props.highlight == "Clients"? 'red': 'color'}}  value="clients" variant="contained" onClick={go}>Clients</Button>
                <Button style={{ backgroundColor: props.highlight == "Schedule"? 'red': 'color'}} value="schedule" variant="contained" onClick={go}>Schedule</Button>
                <Button style={{ backgroundColor: props.highlight == "Logs"? 'red': 'color'}} value="logs" variant="contained" onClick={go}>Logs</Button>
                <Button style={{ backgroundColor: props.highlight == "Calc"? 'red': 'color'}} value="calc" variant="contained" onClick={go}>Calculator</Button>
                <Button style={{ backgroundColor: props.highlight == "Config"? 'red': 'color'}} value="setup" variant="contained" onClick={go}>Setup</Button>
            </ButtonGroup>
    );
}

