import {
    Stack,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Button,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import React from "react";

class CTRL_CalcListAssets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assets: props.assets
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateDatabase = this.updateDatabase.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.assets !== this.props.assets) {
            this.setState({assets: this.props.assets});
        }
    }

    handleInputChange(field, value, index) {
        const assets = [...this.state.assets];
        if (field === 'uname') {
            assets[index].uname = value;
        } else if (field === 'name') {
            assets[index].attr.name = value;
        } else if (field === 'calc') {
            assets[index].attr.calc = value;
            this.updateDatabase(assets[index], index);
        } else if (field === 'price') {
            assets[index].attr.price = value;
        } else if (field === 'optional' || field === 'visible') {
            assets[index].attr[field] = !assets[index].attr[field];
        }
        this.setState({assets});
    }


    async updateDatabase(item, index) {
        console.log('Updating:', item);

        const response = await fetch("/api/calcconfig/storeasset", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(item),
        });
        //const {file_id} = await response.json();
    }

    render() {
        const options = {"length": "Length", "area": "Area", "volume": "Volume", "count": "Count"};

        if (!this.state.assets) {
            return "Loading";
        }

        return (
            <Stack spacing={1}>
                {this.state.assets.map((item, index) => (
                    <FormControl fullWidth variant="outlined" key={item._id}>
                        <Grid container>
                            <Grid item xs={2}>
                                <TextField
                                    value={item.uname}
                                    onChange={(e) => this.handleInputChange('uname', e.target.value, index)}
                                    onBlur={() => this.updateDatabase(item, index)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={item.attr.name}
                                    onChange={(e) => this.handleInputChange('name', e.target.value, index)}
                                    onBlur={() => this.updateDatabase(item, index)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined">
                                    <InputLabel>{item.attr.name}</InputLabel>
                                    <Select
                                        name={item.attr.name}
                                        value={item.attr.calc}
                                        onChange={(e) => this.handleInputChange('calc', e.target.value, index)}
                                    >
                                        {Object.entries(options).map(([key, value]) => (
                                            <MenuItem key={key} value={key}>
                                                {value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <TextField
                                    value={item.attr.price}
                                    onChange={(e) => this.handleInputChange('price', e.target.value, index)}
                                    onBlur={() => this.updateDatabase(item, index)}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={item.attr.optional}
                                            onChange={() => this.handleInputChange('optional', null, index)}
                                        />
                                    }
                                    label="Optional"
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={item.attr.visible}
                                            onChange={() => this.handleInputChange('visible', null, index)}
                                        />
                                    }
                                    label="Visible"
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button variant="outlined"
                                        onClick={() => this.updateDatabase(item, index)}>Edit</Button>
                            </Grid>
                        </Grid>
                    </FormControl>
                ))}
            </Stack>
        );
    }
}

export default CTRL_CalcListAssets;
