import {Button} from "@mui/material";
import React from "react";

class ButtonAddSegment extends React.Component {

    addScaffSegment = () => {
        const {itemtemplate, scaffItemList, setScaffItemList} = this.props;

        const fn_item = () => {
            let tempList = [];
            for (var key in itemtemplate) {
                if (itemtemplate[key]) {
                    tempList.push({
                        "name": itemtemplate[key].name,
                        "result": itemtemplate[key].result,
                        "price": itemtemplate[key].price,
                        "active": itemtemplate[key].active
                    });
                }
            }
            return tempList;
        }

        const newItem = {
            ...fn_item(),
            id: Date.now() // or any other unique ID generation method
        };

        setScaffItemList([...scaffItemList, newItem]);
    }

    render() {
        return (
            <Button variant="outlined" onClick={this.addScaffSegment}>Add segment</Button>
        );
    }
}

export default ButtonAddSegment;
