import {InputAdornment, Divider, ToggleButton, Switch, Autocomplete, Button, Checkbox, Select, MenuItem, OutlinedInput, Grid, Dialog, DialogTitle, DialogContent, InputLabel, FormControl, FormControlLabel,NativeSelect, Stack, TextField, DialogActions, Tooltip, Paper, Box, Typography, IconButton} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import {format} from 'date-fns'
import React from "react";
import CalcItem from "./CTRL_CalcItem";

class CTRL_CalcHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }


    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    render() {
        return (
            <div/>
            )
    }
}
export default CTRL_CalcHeader;