import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    TextField
} from "@mui/material";
import React from "react";
import {GridCEName, GridCEValue} from "../../../Controls/STL_Item";

class DLG_CalcAssignElementToCategory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSetup: "category", // default to CALCULATOR
            categories: []
        };
        this.getEnabledElements = this.getEnabledElements.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.modalOpen != this.props.modalOpen && this.props.modalOpen) {
            console.log(this.props.category)
            this.getEnabledElements(this.props.category._id)
        }
    }

    getEnabledElements(categoryID) {
        fetch('/api/calcconfig/getEnabledElements/' + categoryID) // replace with your API URL
            .then((response) => response.json())
            .then(data => {
                if (data) {
                    this.setState({categories: data});
                } else {
                    this.setState({categories: []});
                }
            })
            .catch((error) => {
                this.setState({categories: []});
            });

    }

    handleCheckboxChange = (id) => {
        this.setState(prevState => ({
            categories: prevState.categories.map(category =>
                category._id === id
                    ? {...category, selected: !category.selected}
                    : category
            )
        }));
    }
    closeModal = () => {
        console.log("closing")
        // Gather the updated category data
        // ...

        // Invoke the parent's save handler
        this.props.fn_close();
        this.setState({categories: []})
    }
    handleSave = () => {
        // Gather the updated category data
        // ...

        // Invoke the parent's save handler
        this.props.fn_close(this.state.categories);
        this.setState({categories: []})
    }

    render() {


        const {categories} = this.state;

        const showCategories = categories.map((category) => (
            <Grid container key={category._id}>
                <Grid item xs={6}>{category.name}:</Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!category.selected}
                                onChange={() => this.handleCheckboxChange(category._id)}
                                name={category.name}
                            />
                        }
                        label={category.selected ? 'Selected' : 'Not Selected'}
                    />
                </Grid>
            </Grid>
        ));

        return (
            <Dialog open={this.props.modalOpen}
                    onClose={this.props.fn_close}
                    aria-labelledby="edit-dialog-title"
                    sx={{
                        '& .MuiDialog-paper': {
                            width: '50%',
                            maxHeight: '90%'
                        }
                    }}
            >
                < DialogTitle id="edit-dialog-title">Edit elements
                    for: {this.props.category ? this.props.category.attr.name : ''}</DialogTitle>
                <DialogContent>
                    {!this.state.categories ? "Loading..." : showCategories}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeModal} color="primary">Cancel</Button>
                    <Button onClick={this.handleSave} color="primary">Save</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default DLG_CalcAssignElementToCategory