
import { styled } from '@mui/material/styles';
import {Paper, Grid} from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' ? '#232b34' : '#fff',
          ...theme.typography.body2,
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          marginRight: theme.spacing(0.5),
          marginBottom: theme.spacing(1),
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(.5),
          textAlign: 'left',
          color: theme.palette.text.secondary,
          display: 'flex',
          flexDirection: 'row',
        }));

const Item2 = styled(Paper)(({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' ? '#08a9a7' : '#fff',
          ...theme.typography.body2,
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          marginRight: theme.spacing(0.5),
          marginBottom: theme.spacing(1),
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(.5),
          textAlign: 'left',
          color: theme.palette.text.secondary,
          display: 'flex',
          flexDirection: 'row',
        }));

const Item2off = styled(Paper)(({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' ? '#222e33' : '#fff',
          ...theme.typography.body2,
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          marginRight: theme.spacing(0.5),
          marginBottom: theme.spacing(1),
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(.5),
          textAlign: 'left',
          color: theme.palette.text.secondary,
          display: 'flex',
          flexDirection: 'row',
        }));


const CalcName = styled(Paper)(({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' ? '#222e33' : '#fff',
          ...theme.typography.body2,
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          marginRight: theme.spacing(0.5),
          marginBottom: theme.spacing(1),
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(.5),
          textAlign: 'left',
          color: theme.palette.text.secondary,
          display: 'flex',
          flexDirection: 'row',
        }));


const GridCName = styled(Grid)(({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' ? '#222e33' : '#fff',
          ...theme.typography.body2,
          paddingRight: theme.spacing(1),
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          textAlign: 'right',
          fontSize:'.6em',
          color: theme.palette.text.secondary,
        }));

const GridCValue  = styled(Grid)(({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' ? '#222e33' : '#fff',
          ...theme.typography.body2,
          paddingLeft: theme.spacing(1),
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          textAlign: 'left',
          fontSize:'.6em',
          fontWeight:'bold',
          color: theme.palette.text.secondary,
        }));
const GridC  = styled(Grid)(({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' ? '#354850' : '#fff',
          ...theme.typography.body2,
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          color: theme.palette.text.secondary,
        }));
const GridCEName = styled(Grid)(({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' ? '#222e33' : '#fff',
          ...theme.typography.body2,
          paddingRight: theme.spacing(1),
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          textAlign: 'right',
          fontSize:'2em',
          color: theme.palette.text.secondary,
        }));

const GridCEValue  = styled(Grid)(({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' ? '#222e33' : '#fff',
          ...theme.typography.body2,
          paddingLeft: theme.spacing(1),
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          textAlign: 'left',
          fontSize:'2em',
          fontWeight:'bold',
          color: theme.palette.text.secondary,
        }));



export {Item, Item2, Item2off, CalcName,GridCName, GridCEValue,GridCEName, GridCValue, GridC}