import {Autocomplete, Box, Grid, IconButton, TextField} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import React from "react";


class JobList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addresses: [],
            id_hire: null,
        };
    }

    async componentDidMount() {
        if (this.props.row) {
            try {
                const response = await fetch('/api/config/idxaddresses/' + this.props.row.client_id);
                const data = await response.json();
                this.setState({addresses: data});
            } catch (error) {
                console.error('An error occurred:', error);
            }
        }
    }

    render() {

        const updateHireId = (event, clientname, reason) => {
            if (reason === "selectOption" || reason === "remove-option") {
                this.setState(prevState => ({
                    ...prevState,               // keep all other key-value pairs
                    id_hire: Object.keys(clientname)[0]
                }))
            }
        }
        const deleteJob = (event, clientname, reason) => {
            fetch('/api/job/deletejob', {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify({
                        hashcode_job: this.props.row.hashcode_job,
                    }
                ),
            }).then(res => res.json()).then(
                data => {
                    {
                        this.props.fn_update()
                    }
                }
            );

        }
        const updateHire = (event, clientname, reason) => {
            if (reason != "reset") {
                // setJobForm({...jobForm, name_client: newValue})
            }
            fetch('/api/job/assignhire', {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify({
                        hashcode_job: this.props.row.hashcode_job,
                        hashcode_hire: this.props.row.hashcode_hire,
                        id_hire: this.state.id_hire
                    }
                ),
            }).then(res => res.json()).then(
                data => {
                    {
                        this.props.fn_update()
                    }
                }
            );
            //this.props.currentClient({"client":[clientname, Number(String(event.target.id).replace("client_name-option-", ""))]});

        }

        const checkQuoteJob = (event, clientname, reason, details) => {
            console.log(event.target.value)
            if (reason === "clear") {
                this.setState(prevState => ({
                    ...prevState,               // keep all other key-value pairs
                    id_hire: null

                }))
            }
            if (reason === "selectOption") {
                this.setState(prevState => ({
                    ...prevState,               // keep all other key-value pairs
                    id_hire: Object.keys(details.option)[0]

                }))
                console.log(Object.keys(details.option)[0])
                fetch('/api/quotation/gethire/' + Object.keys(details.option)[0]).then(res => res.json()).then(data => {

                });
            }
        }

        const updateQuote = () => {
            this.props.fn_update("test")
        }

        if (!this.state.addresses.address_list) {
            return (
                <Grid container>
                    <Grid item xs={1.5}>
                        <div>Loading...</div>
                    </Grid>
                    <Grid item xs={1.5}>
                        <IconButton
                            color="error"
                            onClick={deleteJob}
                        >
                            <HighlightOffIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                </Grid>
            )
        }

        return (
            <Box width="100%">
                <Grid container>
                    <Grid item xs={1.5}>
                        <IconButton
                            color="error"
                            onClick={deleteJob}
                        >
                            <HighlightOffIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={9}>
                        <Autocomplete
                            freeSolo
                            id="client_name"
                            onChange={(event, newValue, reason, details) =>
                                updateHireId(event, newValue, reason, details)
                            }
                            defaultValue=""
                            getOptionLabel={(option) => (option ? Object.values(option)[0] : "")}
                            options={this.state.addresses.address_list}
                            renderOption={(props, option, {selected}) => {
                                // const isSpecial = option.startsWith("4");
                                const isSpecial = false;
                                const address = Object.values(option)[0];
                                return (
                                    <li {...props}
                                        key={Object.keys(option)[0]}
                                        style={{
                                            color: isSpecial ? "red" : "inherit",
                                            backgroundColor: isSpecial ? "lightgreen" : "inherit"
                                        }}>
                                        {address}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    label="Select job"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        {this.state.id_hire ?
                            <IconButton pt={1} variant="contained" aria-label="Confirm" color="current" size="large"
                                        onClick={updateHire}>
                                <CheckCircleIcon/>
                            </IconButton> : ""}
                    </Grid>
                </Grid>
            </Box>
        )
    }
}


export default JobList;