import {
    Paper, Checkbox,
    FormControl,
    Grid, InputAdornment,
    Stack,
    TextField,
    Typography,
    Divider, MenuItem, Select
} from "@mui/material";

import {styled} from '@mui/material/styles';
import React from "react";
import {teal} from '@mui/material/colors';
import {Item, Item2, Item2off} from "../STL_Item";

class CalcItem extends React.Component {

    constructor(props) {
        super(props);
        const defaultOption = props.elements.attr?.menulist?.[0]?.option || null;
        const defaultPrice = props.elements.attr?.menulist?.[0]?.price || props.elements.attr.price || 0
        const defaultCalc = props.elements.attr?.menulist?.[0]?.calc || props.elements?.attr.calc || ""
        const defaultState = {
            calc:defaultCalc,
            active: false,
            price: defaultPrice,
            length: 0,
            height: 0,
            depth: 0,
            finalprice: 0,
            option: defaultOption,  // Setting default option
        };
        this.state = this.props.loadedAsset ? {...defaultState, ...this.props.loadedAsset} : defaultState;
    }

    componentDidMount() {
        //console.log("assets:", this.props.elements)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.price === 0) {
            this.setState({price: this.props.elements.attr.price});
        }

        if (this.state !== prevState) {
            const conditions = [
                this.state.length !== prevState.length,
                this.state.height !== prevState.height,
                this.state.depth !== prevState.depth,
                this.state.price !== prevState.price,
                this.state.active !== prevState.active
            ];
            if (conditions.some(cond => cond)) {
                this.calculateAndUpdate();
            }
        }
    }

    calculateAndUpdate() {
        let finalprice = 0;
        let dimensions = {};

        if (!this.state.active) {
            this.setState({length: 0, height: 0, depth: 0});
        }

        switch (this.state.calc) {
            case 'volume':
                finalprice = this.state.length * this.state.height * this.state.depth * this.state.price;
                dimensions = {
                    length: parseFloat(this.state.length),
                    height: parseFloat(this.state.height),
                    depth: parseFloat(this.state.depth)
                };
                break;
            case 'area':
                finalprice = this.state.length * this.state.height * this.state.price;
                dimensions = {
                    length: parseFloat(this.state.length),
                    height: parseFloat(this.state.height)
                };
                break;
            default:
                finalprice = this.state.length * this.state.price;
                dimensions = {
                    length: parseFloat(this.state.length)
                };
                break;
        }

        this.setState({finalprice});
        this.props.fn_update({
            _id:this.props.elements._id,
            price: this.state.price,
            finalprice,
            dimensions,
            comment: '',
            active: this.state.active,
            option:this.state.option,
            name:this.props.elements.attr.name,
        });
    }


    handleTFChange = (key, value) => {
        this.setState({[key]: parseFloat(value)});
    }

    render() {

        const {name, optional, price, unit} = this.props.elements?.attr || {};
        const calc = this.props.elements.attr?.menulist?.[0]?.calc || this.props.elements?.attr.calc || ""
        const {_id, type} = this.props.elements || {};
        const menulist = this.props.elements.attr?.menulist || [];

        const TF = (key, adr) => {
            return (
                <TextField
                    key={key}
                    onChange={(event) => this.handleTFChange(key, event.target.value)}
                    required
                    margin="none"
                    size="small"
                    label={key}
                    name="price"
                    value={this.state[key]}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">{adr}</InputAdornment>,
                    }}
                />
            )
        }
        const Disabled = () => {
            return (
                <Grid container>
                    <Grid item xs={10}>
                        <Typography pb={1} align="center" sx={{fontSize: 14}}
                                    color="Gray">{name}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography align="right" sx={{fontSize: 10}}
                                    color="text.secondary">£{price}</Typography>
                    </Grid>
                </Grid>
            )
        }

        const Enabled = () => {
            if (type === 'assetmenu') {
                return (
                        <Stack >
                            <Typography width='90%' sx={{fontSize: 20, fontWeight: 600}}
                                        color='#69A9A3'>{name}</Typography>
                        </Stack>

                );
            } else {
                return (
                    <Stack direction="row" alignItems="center">
                        <Typography width='90%' sx={{fontSize: 14, fontWeight: 600}}
                                    color='#69A9A3'>{name}</Typography>
                        <Stack width='23%'>
                            <TextField
                                onChange={(event) => {
                                    this.setState({price: event.target.value})
                                }}
                                required
                                margin="dense"
                                size="small"
                                label="price/unit"
                                name="price"
                                value={this.state.price}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                            />
                        </Stack>
                    </Stack>
                );
            }
        }

        const handleSelectionChange = (item) => {
            this.setState({option:item.option})
            this.setState({price:item.price})
        }

        const selectionMenu = () => {
            if (!menulist || menulist.length === 0) {
                return <div></div>;
            }
            return (
                <Grid container>
                    <Grid item xs={10}>
                <Item>
                    <Select
                        value={this.state.option ?? null}
                        size="small"
                        onChange={(event) => {
                            const selectedItem = menulist.find(item => item.option === event.target.value);
                            handleSelectionChange(selectedItem);
                        }}
                        fullWidth={true}
                    >
                        {
                            menulist.map((item) => (
                                <MenuItem key={item.option} value={item.option}>{item.name}</MenuItem>
                            ))
                        }
                    </Select></Item></Grid>
                            <Grid item xs={2}>
                            <TextField
                                onChange={(event) => {
                                    this.setState({price: event.target.value})
                                }}
                                required
                                margin="dense"
                                size="small"
                                label="price/unit"
                                name="price"
                                value={this.state.price}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
            )

        }

        const renderCalc = () => {
            const fields = {
                volume: ["length", "height", "depth"],
                area: ["length", "height"],
                default: ["length"]
            };

            const currentFields = fields[calc] || fields.default;

            return (
                <Stack direction='row' width="100%">
                    {currentFields.map(field => TF(field, "m"))}
                </Stack>
            );
        };

        if (!this.props.elements) {
            return <div>Loading</div>
        }

        return (
            <FormControl fullWidth key={this.props.uid}>
                <Grid container alignItems="flex-end">
                    <Grid item xs={1} m={-1}>
                        <Checkbox checked={this.state.active}
                                  onChange={(event) => this.setState({active: event.target.checked})}
                                  name={name}
                                  sx={{color: teal[500], '&.Mui-checked': {color: teal[300],},}}/>
                    </Grid>
                    <Grid item xs={11}>
                        {this.state.active ? <Enabled/> : <Disabled/>}
                    </Grid>
                </Grid>
                <Divider/>
                {this.state.active ?
                    <Grid container alignItems="center">
                        <Grid container>
                                {selectionMenu()}
                            <Grid item xs={9.5}>
                                <Item>
                                    {renderCalc()}
                                </Item>
                            </Grid>
                            <Grid item xs={2.5}>
                                {this.state.finalprice > 0 ? <Item2>
                                        <TextField
                                            required
                                            margin="none"
                                            size="small"
                                            label="cost (£)"
                                            name="price"
                                            value={this.state.finalprice}
                                        />
                                    </Item2> :
                                    <Item2off>
                                        <TextField
                                            required
                                            margin="none"
                                            size="small"
                                            label="cost (£)"
                                            name="price"
                                            value={this.state.finalprice}
                                        />
                                    </Item2off>
                                }
                            </Grid>
                        </Grid>
                    </Grid> : null
                }
            </FormControl>
        )
    }
}

export default CalcItem;