import React from 'react';
import {TextField, Select, FormControl, FormControlLabel, InputLabel, MenuItem, Checkbox} from "@mui/material";

const SL = ({name, label, value, onChange, options}) => (
    <FormControl>
        <InputLabel>{label}</InputLabel>
        <Select name={name} value={value} onChange={onChange}>
            {options.map((option, index) => (
                <MenuItem key={index} value={option}>
                    {option}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

const measurementType = ['Count', 'Length', 'Area', 'Volume']

export const ElementField = ({attr, onChange}) => (
    <>
        <TextField
            name="attr.name"
            label="Full Name"
            value={attr.name}
            onChange={onChange}
        />
        <SL
            name="attr.calc"
            label="Type"
            value={attr.calc}
            onChange={onChange}
            options={measurementType}
        />
        {attr.unit}
        <TextField
            name="attr.price"
            label="Default price"
            value={attr.price}
            onChange={onChange}
        />
        <FormControlLabel
            control={
                <Checkbox
                    checked={attr.optional}
                    onChange={onChange}
                    name="attr.optional"
                    color="primary"
                />
            }
            label="Optional"
        />
    </>
);

export const ElementMenuField = ({attr, onChange}) => (
    <>
        <TextField
            name="attr.name"
            label="Full Name"
            value={attr.name}
            onChange={onChange}
        />
        <SL
            name="attr.calc"
            label="Type"
            value={attr.calc}
            onChange={onChange}
            options={measurementType}
        />
        {attr.unit}
        <TextField
            name="attr.price"
            label="Default price"
            value={attr.price}
            onChange={onChange}
        />
        <FormControlLabel
            control={
                <Checkbox
                    checked={attr.optional}
                    onChange={onChange}
                    name="attr.optional"
                    color="primary"
                />
            }
            label="Optional"
        />
    </>
);

export const CategoryField = ({attr, onChange}) => (
    <>
        <TextField
            name="attr.name"
            label="Full Name"
            value={attr.name}
            onChange={onChange}
        />
        <TextField
            name="attr.price"
            label="Price"
            value={attr.price}
            onChange={onChange}
        />
        {/* Add more fields as needed */}
    </>
);

export const PurposeField = ({attr, onChange}) => (
    <>
        <TextField
            name="attr.name"
            label="Purpose Specific Field 1"
            value={attr.name}
            onChange={onChange}
        />
        <TextField
            name="attr.price"
            label="Purpose Specific Field 2"
            value={attr.price}
            onChange={onChange}
        />
        {/* Add more fields as needed */}
    </>
);