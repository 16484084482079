import {Paper, Box, Grid, Button, Card, Stack, CardActions} from '@mui/material';
import React from "react";

import {saveAs} from "file-saver";

import DLG_NavBar from "../DLG_NavBar";
import CalcReport from "../Controls/Calculator/CTRL_CalcReport";
import axios from "axios";
import fileDownload from "js-file-download";
import QuoteSelector from "../DLG_QuoteSelector";
import ScaffSegment from "../Controls/Calculator/CTRL_CalcSegment";
import CalcSummary from "../Controls/Calculator/CTRL_CalcSummary";
import ButtonExportPDF from "../Controls/Calculator/CTRL_CalcFetchPDF";
import ButtonAddCard from "../Controls/Calculator/CTRL_CalcAddSegment";
import CTRL_CalcHeader from "../Controls/Calculator/CTRL_CalcHeader";

export default function MainCalc() {
    const [template, setTemplate] = React.useState({})

    const [totalCalculation, setTotalTotalCalculation] = React.useState({
        'job_id': null,
        'user_id': null,
        'name': "",
        'discount': 0,
        'comment': "",
        'priceList': null,
    })
    const [submitForm, setSubmitForm] = React.useState("")
    const [scaffItemList, setScaffItemList] = React.useState([]);
    const [scaffSegments, setScaffSegments] = React.useState([]);
    const [scaffQuotation, setScaffQuotation] = React.useState([
        {
            id_quote: null,
            name: null,
            id_client: null,
            id_hire: null,
            quotelist: [],
        }
    ])

    const [calcCategoryList, setCalcCategoryList] = React.useState({})

    React.useEffect(() => {
        io_getcategory('category')
        onHandleChange()
    }, []);

    React.useEffect(() => {

        console.log("SCFFLIST", scaffItemList)
        console.log("CALCLIST", calcCategoryList)
    }, [calcCategoryList, scaffItemList]);

    const updateTotalCalculation = (jobID) => {
        setTotalTotalCalculation((prevState) => ({
            ...prevState,
            job_id: jobID,
            priceList: scaffItemList,
        }));
    };

    async function fetchData(url, setter) {
        const response = await fetch(url, {
            method: "GET",
            headers: {"Content-Type": "application/json"},
        });
        setter(await response.json());
    }

    async function io_getcategory(option) {
        switch (option) {
            case('category'):
                fetchData("/api/calcconfig/getcategory", setCalcCategoryList);
                break;
            default:
                break;
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (submitForm === "delete") {

        } else {

        }
    };

    const handleChange = (event) => {

    };

    const onHandleChange = (src, dest, index) => {

    };

    const onJobChange = (clientItem) => {
        saveQuote()
    }

    const cardChange = (value, key) => {
        if (scaffItemList[key] === undefined) {
            setScaffItemList((prevList) => [...prevList.slice(0, key), value, ...prevList.slice(key + 1)]);
        } else {
            setScaffItemList((prevList) => {
                let updatedList = [...prevList];
                updatedList[key] = value;
                return updatedList;
            });
        }
    };
    const removeItemPriceListAtGivenIndex = (index) => {
        const itemToRemove = scaffItemList[index];
        setScaffItemList(prevList => prevList.filter(item => item.id !== itemToRemove.id));
    };


    const quotationSelect = (action, option) => {
        switch (action) {
            case "import":
                importScaffQuotation(option)
                break;
            case "save":

                break;
            default:

                break;
        }
    }

    const updateScaffSegmentData = (index, data) => {
        // Logic to update the ScaffSegment instance using setState
        setScaffSegments((prevScaffSegments) => {
            const updatedScaffSegments = [...prevScaffSegments];
            updatedScaffSegments[index] = {...updatedScaffSegments[index], ...data};
            return updatedScaffSegments;
        });
    };

    function importScaffQuotation(id_quote) {
        const fn_item = () => {
            let tempList = [];
            for (var key in template) {
                if (template[key]) {
                    tempList.push({
                        "name": template[key].name,
                        "result": template[key].result,
                        "price": template[key].price,
                        "active": template[key].active
                    });
                }
            }
            return (tempList);
        }
        fetch("/api/quotation/import/" + id_quote)
            .then((res) => res.json())
            .then((data) => {
                setScaffQuotation(data)
                console.log("loaded data", data)
                data.quotelist.forEach((item, index) => {
                    updateScaffSegmentData(index, item);
                });
            })
        //}));
    }

    async function saveQuote() {
        const data = {totalCalculation};
        const response = await fetch("/api/quotation/store", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data.totalCalculation),
        });
        const {file_id} = await response.json();
    }

    async function saveScaffQuotation(event) {
        switch (event) {
            case('save'):
                const response = await fetch("/api/quotation/store", {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({'jobid': 1, 'quote': scaffItemList}),
                });

                break;
            default:
                break;
        }
    };

    return (
        <Box>
            <form onSubmit={handleSubmit} onChange={handleChange}>
                <Grid container spacing={0}>
                    <Grid item xs={1}>
                        <ButtonAddCard
                            scaffItemList={scaffItemList}
                            setScaffItemList={setScaffItemList}
                            fn_AddSegment/>
                    </Grid>
                    <Grid item xs={1}>
                        <ButtonExportPDF currentquote={totalCalculation}/>
                    </Grid>
                    <Grid item xs={10}>
                        <QuoteSelector currentquote={totalCalculation} fn_update={quotationSelect}/>
                    </Grid>
                    <Grid item xs={3}>
                        <CalcSummary priceList={scaffItemList}/>
                    </Grid>
                    <Grid item xs={9}>
                        <Stack>
                            <Card>
                                Header
                            </Card>
                            <Box width="100%" sx={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                p: 1,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                                overflow: "hidden",
                                overflowX: "scroll",
                            }}>
                                {scaffItemList.map((item, index) => {
                                    return (
                                        <Card key={'card-' + item.id} variant="outlined"
                                              sx={{minWidth: 350, maxWidth: 450}}>
                                            <ScaffSegment
                                                category={calcCategoryList.category}
                                                design={calcCategoryList.design}
                                                index={index}
                                                uid={item.id}
                                                fn_update={(value, key) => cardChange(value, key)}
                                            />
                                            <CardActions>
                                                <Button size="small">Reset</Button>
                                                <Button size="small"
                                                        onClick={() => removeItemPriceListAtGivenIndex(index)}>Remove</Button>
                                            </CardActions>
                                        </Card>
                                    )
                                })}
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={0}>
                <DLG_NavBar highlight={"Calc"}/>
            </Paper>
        </Box>
    );
}