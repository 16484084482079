import {
    Button,
    Card,
    CardActions,
    CardContent,
    FormGroup,
    Grid,
    Stack,
    Typography,
    TextField,
    MenuItem, Select, Divider
} from "@mui/material";
import React from "react";
import {saveAs} from "file-saver";


class ButtonExportPDF extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {

        const {totalCalculation} =this.props

        async function fetchAndDownloadPDF() {
            const data = {totalCalculation};
            const response = await fetch("/api/quotation/pdf", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(data.totalCalculation),
            });
            const {file_id} = await response.json();

            // Download the PDF file with the given file_id
            const fileResponse = await fetch('/static/downloads/' + file_id + '.pdf');
            const fileBlob = await fileResponse.blob();
            saveAs(fileBlob, "exported_data.pdf");
        }

        return (
            <Button variant="outlined" onClick={fetchAndDownloadPDF}>Download PDF</Button>
        );
    }
}


export default ButtonExportPDF;