import {Grid, InputAdornment, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import React from "react";
import {Item, Item2, Item2off} from "../STL_Item";

class CalcSelectorCategory extends React.Component {
    constructor(props) {
        super(props);
        const {category = {}, design = {}, loadedAsset} = props;

        //const defaultCalc = props.design?.calc || ""
        //const defaultPrice = props.design.menulist?.[0]?.price || 0
        const defaultState = {
            categoryid: category[0]._id,
            categoryname: category[0].attr.name,
            designid: null,
            designname: design.menulist?.[0]?.name,
            calc: design.calc || "",
            selectedcategory: 'ID',
            selecteddesign: "DS",
            name: "",
            price: design.menulist?.[0]?.price || 0,
            margin: category[0].attr?.margin || 0,
            length: 0,
            height: 0,
            depth: 0,
            finalprice: 0,
            customMargin: 0,
            comment: '',
        }
        this.state = loadedAsset ? {...defaultState, ...loadedAsset} : defaultState;
    }

    calcCategoryChange = (event, option) => {
        console.log("STATE", option.props)

        const updatedState = {
            selectedcategory: this.state.selectedcategory,
            selecteddesign: this.state.selecteddesign,
            ...option.props
        };

        if (event.target.name === 'category') {
            updatedState.selectedcategory = event.target.value;

            // Extract datamargin from the selected category and update state
            const marginValue = option.props['datamargin'];
            if (marginValue !== undefined && marginValue !== null) {
                updatedState.margin = parseFloat(marginValue);
            }
        } else if (event.target.name === 'design') {
            updatedState.selecteddesign = event.target.value;

            // Extract dataprice from the selected design and update state
            const priceValue = option.props['dataprice'];
            if (priceValue !== undefined && priceValue !== null) {
                updatedState.price = parseFloat(priceValue);
            }
        }
        this.setState(updatedState, () => {
            this.calculateAndUpdate();
        });
    }

    componentDidMount() {
        console.log(this.props.category)
    }

    componentDidUpdate(prevProps, prevState) {
        const changes = [
            'length', 'height', 'depth', 'customMargin',
        ].some(attr => this.state[attr] !== prevState[attr]);

        if (changes && this.state.categoryid) {
            console.log(this.state.categoryid)
            this.calculateAndUpdate();
        }
    }

    calculateAndUpdate(data = this.state) {
        let finalprice = 0;
        let dimensions = {};

        const {calc, length, height, depth, price, margin, customMargin, categoryid, comment} = data;
        const calculation = parseFloat(price) + parseFloat(margin) + parseFloat(customMargin);

        switch (calc) {
            case 'volume':
                finalprice = length * height * depth * calculation;
                dimensions = {length, height, depth};
                break;
            case 'area':
                finalprice = length * height * calculation;
                dimensions = {length, height};
                break;
            default:
                finalprice = length * calculation;
                dimensions = {length};
                break;
        }

        this.setState({finalprice});
        this.props.fn_update({
        ...this.state,
            categoryid: categoryid, // directly use categoryid from destructure above
            finalprice,
            dimensions
        });
    }


    handleTFChange = (key, value) => {
        this.setState({[key]: parseFloat(value)});
    }

    render() {
        const {category = [], design = {}} = this.props;
        const {name, optional, unit, calc = "", _id, type} = design;

        const TF = (key, adr) => (
            <TextField
                key={key}
                onChange={(event) => this.handleTFChange(key, event.target.value)}
                required
                margin="none"
                size="small"
                label={key}
                name="price"
                value={this.state[key]}
                InputProps={{
                    endAdornment: <InputAdornment position="start">{adr}</InputAdornment>,
                }}
            />
        );

        const renderCalc = () => {
            const fields = {
                volume: ["length", "height", "depth"],
                area: ["length", "height"],
                default: ["length"]
            };

            return (
                <Stack direction='row' width="100%">
                    {(fields[calc] || fields.default).map(field => TF(field, "m"))}
                </Stack>
            );
        };

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6" component="div">Scaffold Type</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Select
                        size="small"
                        value={this.state.selectedcategory}
                        onChange={this.calcCategoryChange}
                        fullWidth={true}
                        name='category'
                    >
                        {
                            this.props.category?.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item.uname}
                                    categoryname={item.attr.name||''}
                                    categoryid={item._id}
                                    margin={item.attr.margin}
                                >
                                    {item.attr.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
                <Grid item xs={3}>
                    <Item2off>
                        <TextField
                            required
                            margin="none"
                            size="small"
                            label="margin (£)"
                            name="margin"
                            value={this.state.margin}
                        />
                    </Item2off>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="div">Custom Adjustments</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        margin="none"
                        size="small"
                        label="Comment"
                        name="comment"
                        value={this.state.comment}
                        onChange={e => this.setState({comment: e.target.value})}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        required
                        margin="none"
                        size="small"
                        label="Custom Margin (£)"
                        name="customMargin"
                        value={this.state.customMargin}
                        onChange={e => this.setState({customMargin: parseFloat(e.target.value || 0)})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" component="div">Scaffold Purpose</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Select
                        size="small"
                        value={this.state.selecteddesign}
                        onChange={this.calcCategoryChange}
                        fullWidth={true}
                        name='design'
                    >
                        {
                            this.props.design.menulist?.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item.option}
                                    price={item.price}
                                    designname={item.name||''}
                                    designid={item.option._id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </Grid>
                <Grid item xs={3}>
                    <Item2off>
                        <TextField
                            required
                            margin="none"
                            size="small"
                            label="cost (£)"
                            name="price"
                            value={String(this.state.price) + "+" + String(this.state.customMargin) + "+" + String(this.state.margin)}

                        />
                    </Item2off>
                </Grid>
                <Grid item xs={9}>
                    <Item>
                        {renderCalc()}
                    </Item>
                </Grid>
                <Grid item xs={3}>
                    {this.state.finalprice > 0 ? <Item2>
                            <TextField
                                required
                                margin="none"
                                size="small"
                                label="cost (£)"
                                name="price"
                                value={this.state.finalprice}
                            />
                        </Item2> :
                        <Item2off>
                            <TextField
                                required
                                margin="none"
                                size="small"
                                label="cost (£)"
                                name="price"
                                value={this.state.finalprice}
                            />
                        </Item2off>
                    }
                </Grid>
            </Grid>
        )
    }
}

export default CalcSelectorCategory;