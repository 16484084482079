import {
    Stack,
} from '@mui/material';
import React from "react";

class CTRL_CalcListDesigns extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handleChange(event, newSelection) {
        if (newSelection) {
            this.setState({
            });
        }
    }

    render() {

        //const {assets} = this.props

        //if (!this.props.assets) {return  "Loading"}

        return (
            <Stack spacing={1}>
Test
            </Stack>
        )
    }
}

export default CTRL_CalcListDesigns;