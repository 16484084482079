import {
    Button,
    Card,
    CardActions,
    CardContent,
    FormGroup,
    Grid,
    Stack,
    Typography,
    TextField,
    MenuItem, Select, Divider
} from "@mui/material";
import CalcItem from "./CTRL_CalcItem";
import CalcSelectorCategory from "./CTRL_CalcSelectorCategory";
import React from "react";


class ScaffSegment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scaffSegment: {
                'id': 0,
                "name": '',
                'type': '',
                'scafftype': 'ID',
                'price_modifier': 0,
                'result': "",
                'design': {},
                'assetlist': {},
                'menuname': '',
            },
            elements: null,
            assetList: {},
            designList: {},
            categoryType: 0,
            sum: 0,
        }
        this.updateScaffSegment = this.updateScaffSegment.bind(this);
        this.calcSelectChange = this.calcSelectChange.bind(this);
        this.handleElements = this.handleElements.bind(this);
        this.setCategoryValue = this.setCategoryValue.bind(this);
    }

    componentDidMount() {
        this.setState(prevState => ({
            scaffSegment: {                   // object that we want to update
                ...prevState.scaffSegment,    // keep all other key-value pairs
                id: this.props.index,
                name: 'Side_' + this.props.index,
            }
        }))

        if (this.props.scaffSegmentLoadedData) {
            this.setState({scaffSegment: this.props.scaffSegmentLoadedData})
        }
        this.fetchData("/api/calcconfig/getmenuset/" + this.props.category[0]._id)

        async function loadTemplate() {
            const response = await fetch("/api/quotation/get_template", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
            });
            const {file_id} = await response.json();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const assetListChanged = JSON.stringify(this.state.assetList) !== JSON.stringify(prevState.assetList);
        const designListChanged = JSON.stringify(this.state.designList) !== JSON.stringify(prevState.designList);
        const scaffTypeChanged = prevState.scaffSegment.scafftype !== this.state.scaffSegment.scafftype;
        const nameChanged = prevState.scaffSegment.name !== this.state.scaffSegment.name;

        if (assetListChanged || scaffTypeChanged || nameChanged || designListChanged) {
            let sum = 0;
            for (const property in this.state.assetList) {
                if (this.state.assetList.hasOwnProperty(property) && this.state.assetList[property].active) {
                    sum += this.state.assetList[property].finalprice * this.state.assetList[property].active;
                }
            }
            sum += this.state.designList.finalprice || 0;

            const filteredAssetList = {};
            for (const property in this.state.scaffSegment.assetlist) {
                if (this.state.scaffSegment.assetlist.hasOwnProperty(property) &&
                    this.state.scaffSegment.assetlist[property].active === true &&
                    this.state.scaffSegment.assetlist[property].finalprice !== 0 &&
                    !['menscaffarea', 'menuscaffvolume'].includes(property)) {
                    filteredAssetList[property] = this.state.scaffSegment.assetlist[property];
                }
            }

            this.setState({ sum });
            this.props.fn_update({
                'name': this.state.scaffSegment.name,
                'type': this.state.scaffSegment.type,
                'scafftype': this.state.scaffSegment.scafftype,
                'id': this.state.scaffSegment.id,
                'price_modifier': this.state.scaffSegment.price_modifier,
                'result': this.state.scaffSegment.menuname,
                'assetlist': filteredAssetList,
                'designlist': this.state.designList,
            }, this.state.scaffSegment.id);
        }

        if (JSON.stringify(prevProps.scaffSegmentLoadedData) !== JSON.stringify(this.props.scaffSegmentLoadedData) &&
            this.props.scaffSegmentLoadedData.length > 0) {
            this.setState({ scaffSegment: this.props.scaffSegmentLoadedData[this.props.index] });
        }
    }

    async fetchData(url) {
        const response = await fetch(url, {
            method: "GET",
            headers: {"Content-Type": "application/json"},
        });
        const data = await response.json();
        this.setState({elements: data});
    }


    setCategoryValue(option) {

        if (option) {
            console.log("OPTION",option)
            this.setState({designList:option})
        }
        const category = this.props.category;
        const record = category.find(item => item.uname === option.selectedcategory);
        const newCategory = record && record.attr ? record.attr.calc || null : null;

        if (newCategory) {
            this.setState({categoryType: 1})
        } else {
            this.setState({categoryType: 0})
        }
        this.fetchData("/api/calcconfig/getmenuset/" + option.categoryid)



    }

    calcSelectChange(value, key) {
        this.setState(prevState => ({
            assetList: {
                ...prevState.assetList,
                [key]: value
            },
            scaffSegment: {
                ...prevState.scaffSegment,
                assetlist: {
                    ...prevState.scaffSegment.assetlist,
                    [key]: value
                }
            }
        }));
    }

    updateScaffSegment(event, key) {
        this.setState(prevState => ({
            scaffSegment: {
                ...prevState.scaffSegment,
                [key]: event.target.value
            }
        }));
    }

    handleElements() {
        return this.state.elements.map(item => {
            if (!item) {
                return null;
            } else {
                return (
                    <CalcItem
                        key={item._id}
                        name={item.uname}
                        elements={item}
                        fn_update={value => this.calcSelectChange(value, item.uname)}
                    />
                );
            }
        });
    }

    render() {
        const {scaffSegment, elements, sum} = this.state;

        if (!this.state.elements) {
            return <div>Loading...</div>;
        }

        return (
            <FormGroup>
                <Card variant="outlined" sx={{minWidth: 350, maxWidth: 450}}>
                    <CardContent justify="left">
                        <Typography align='center' component='div' variant="h6" sx={{color: 'primary'}}>
                            Segment name
                        </Typography>
                        <TextField
                            required
                            size="small"
                            margin="dense"
                            fullWidth
                            value={scaffSegment.name}
                            onChange={(event) => this.updateScaffSegment(event, 'name')}
                        />
                        <CalcSelectorCategory category={this.props.category} design={this.props.design[this.state.categoryType]}
                                            fn_update={this.setCategoryValue}/>
                        <Grid item xs={12}>
                            {this.handleElements()}
                        </Grid>
                        <Divider/>
                        <Typography variant="h6" component="div">Result</Typography>
                        <Divider/>
                        <TextField fullWidth multiline value={sum}/>
                    </CardContent>
                </Card>
            </FormGroup>
        );
    }
}

export default ScaffSegment;