import {
    Button, IconButton,
    FormControl, Grid, InputLabel,
    Stack, TextField,
    Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import DLG_CalcAssignElementToCategory from "../../../Dialogs/Setup/Calculator/DLG_CalcAssignElementToCategory";

import React from "react";

class CTRL_CalcListCategories extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: props.categories || [],
            isModalOpen: false,
            currentItemIndex: null,
        };
        this.handleChange = this.handleChange.bind(this);
        this.moveUp = this.moveUp.bind(this);
        this.moveDown = this.moveDown.bind(this);
        this.moveToTop = this.moveToTop.bind(this);
        this.moveToBottom = this.moveToBottom.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateDatabase = this.updateDatabase.bind(this);
        this.updateElements = this.updateElements.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    openModal = (index) => {
        this.setState({isModalOpen: true, currentItemIndex: index});
    }

    closeModal = (setElements) => {
        if (setElements){
            this.updateElements(setElements)
            console.log(setElements)
        }
        this.setState({isModalOpen: false, currentItemIndex: null});
    }

    async updateElements(categories) {
        const response = await fetch("/api/calcconfig/storecalcset", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(categories),
        });
        //const {file_id} = await response.json();
    }

    handleChange(event, newSelection) {
        if (newSelection) {
            this.setState({});
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.categories !== prevState.categories) {
            return {categories: nextProps.categories};
        }
        return null;
    }

    moveUp(index) {
        let categories = [...this.props.categories];
        if (index === 0) return;

        let temp = categories[index];
        categories[index] = categories[index - 1];
        categories[index - 1] = temp;

        this.updateOrder(categories);
    }

    moveToTop(index) {
        let categories = [...this.props.categories];
        if (index === 0) return;

        let temp = categories.splice(index, 1);
        categories.unshift(temp[0]);

        this.updateOrder(categories);
    }

    moveDown(index) {
        let categories = [...this.props.categories];
        if (index === categories.length - 1) return;

        let temp = categories[index];
        categories[index] = categories[index + 1];
        categories[index + 1] = temp;

        this.updateOrder(categories);
    }


    moveToBottom(index) {
        let categories = [...this.props.categories];
        if (index === categories.length - 1) return;

        let temp = categories.splice(index, 1);
        categories.push(temp[0]);

        this.updateOrder(categories);
    }

    handleInputChange(field, value, index) {
        const newCategories = [...this.state.categories];

        if (field === 'uname') {
            newCategories[index].uname = value;
        } else if (field === 'name') {
            newCategories[index].attr.name = value;
        } else if (field === 'margin') {
            newCategories[index].attr.margin = value;
        }
        this.setState({categories: newCategories});
    }

    updateDatabase(item, index) {
        const unameSet = new Set(this.state.categories.map(cat => cat.uname));
        if (unameSet.size !== this.state.categories.length) {
            alert('uname must be unique!');
            return;
        }
        this.props.onOrderChange(this.state.categories);
    }

    updateOrder(categories) {
        categories.forEach((category, index) => {
            category.order = index;
        });

        console.log("ORDER", categories)
        this.setState({categories}, () => {
            this.props.onOrderChange(categories);
        });
    }

    render() {

        if (!this.props.categories) {
            return "Loading"
        }
        const {categories} = this.props
        const length = categories.length

        return (
            <div>
                <Stack spacing={1}>
                    {categories.map((item, index) => (
                        <FormControl fullWidth variant="outlined" key={item._id}>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <TextField
                                        value={item.order || index}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField
                                        value={item.uname}
                                        onChange={(e) => this.handleInputChange('uname', e.target.value, index)}
                                        onBlur={() => this.updateDatabase(item, index)}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        value={item.attr.name}
                                        onChange={(e) => this.handleInputChange('name', e.target.value, index)}
                                        onBlur={() => this.updateDatabase(item, index)}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField
                                        value={item.attr.margin}
                                        onChange={(e) => this.handleInputChange('margin', e.target.value, index)}
                                        onBlur={() => this.updateDatabase(item, index)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <IconButton variant="outlined" name='up'
                                                onClick={(e) => this.moveUp(index)}>{index > 0 ?
                                        <ArrowUpwardIcon/> : ""}</IconButton>
                                    <IconButton variant="outlined"
                                                onClick={() => this.moveDown(index)}>{index < (length - 1) ?
                                        <ArrowDownwardIcon/> : ""}</IconButton>
                                    <IconButton variant="outlined" onClick={() => this.moveToTop(index)}>{index > 0 ?
                                        <KeyboardDoubleArrowUpIcon/> : ""}
                                    </IconButton>
                                    <IconButton variant="outlined"
                                                onClick={() => this.moveToBottom(index)}>{index < (length - 1) ?
                                        <KeyboardDoubleArrowDownIcon/> : ""}
                                    </IconButton>
                                    <Grid item xs={1}>
                                        <Button variant="outlined" onClick={() => this.openModal(index)}>Edit</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FormControl>
                    ))
                    }
                </Stack>
                <DLG_CalcAssignElementToCategory
                    modalOpen={this.state.isModalOpen}
                    fn_close={this.closeModal}
                    category={this.state.categories[this.state.currentItemIndex]}
                />
            </div>
        )
    }
}

export default CTRL_CalcListCategories;