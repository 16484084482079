import {
    CircularProgress,
    Dialog,
    DialogTitle,
    Link,
    Switch,
    Stack,
    Divider,
    Paper,
    TextField,
    Typography,
    Button,
    Box,
    Grid,
    FormControlLabel
} from '@mui/material';
import React from "react";
import FormControl from '@mui/material/FormControl';
import {styled} from '@mui/material/styles';
import fileDownload from 'js-file-download'

//import DLG_NavBar from "./DLG_NavBar";
//import CTRL_DBChecker from "./CTRL_DBChecker"
import CTRL_DBChecker from "../CTRL_DBChecker";

import axios from 'axios';
import Slider from '@mui/material/Slider';

function valuetext(value: number) {
    return `${value}°C`;
}

export default function DLG_ConfigAppSetup(opt) {
    const [selected, setSelected] = React.useState(window.location.pathname.split('/')[1].toUpperCase());
    const [file, setFile] = React.useState()
    const [imageUrl, setImageUrl] = React.useState()
    const [config, setConfig] = React.useState({
        'admin_email': "",
        'sender_email': "",
        'sender_user': "",
        'sender_password': "",
        'sender_name': "",
        'sender_host': "",
        'sender_port': "",
        'client_url': "",
        'app_url': "",
        'proxy_email': "",
        'mailing_enabled': false,
        'scheduler_enabled': false,
        'scheduler_timer': 2,
    })
    const [result, setResult] = React.useState({})
    const [report, setReport] = React.useState({})
    const [value, setValue] = React.useState(2);
    const [loadingState, setLoadingState] = React.useState(false)
    const [mailingCheckbox, setMailingCheckbox] = React.useState({
        checked: false,
        message: "Mailing disabled, emails are not sent to clients",
        color: "green"
    })
    const [schedulerCheckbox, setSchedulerCheckbox] = React.useState({
        checked: false,
        message: "Scheduler disabled - only manual sending emails",
        color: "green"
    })
    const [initReady, setInitReady] = React.useState(true);


    const marks = [
        {
            value: -4,
            label: 'All',
        },
        {
            value: -3,
            label: '3w late',
        },
        {
            value: -2,
            label: '2w late',
        },
        {
            value: -1,
            label: '1w late',
        },
        {
            value: 0,
            label: 'no advance',
        },
        {
            value: 1,
            label: '1w advance',
        },
        {
            value: 2,
            label: '2w advance',
        },
        {
            value: 3,
            label: '3w advance',
        },
    ];

    function handleChange(event) {
        setFile(event.target.files[0])
    }

    const rangeChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue);
    };

    function handleMailing(event) {
        setConfig({...config, "mailing_enabled": event.target.checked})
        SetLiveMailing(event.target.checked)
    }

    function SetLiveMailing(isChecked) {
        if (isChecked) {
            setMailingCheckbox({
                checked: true,
                message: "Warning - Mailing enabled - system live - all emails are sent to clients!",
                color: "red"
            })
        } else {
            setMailingCheckbox({
                checked: false,
                message: "Mailing disabled, emails are not sent to clients - use for testing only",
                color: "green"
            })
        }
    }

    function handleScheduler(event) {
        setConfig({...config, "scheduler_enabled": event.target.checked})
        SetLiveScheduler(event.target.checked)
    }

    function SetLiveScheduler(isChecked) {
        if (isChecked) {
            setSchedulerCheckbox({
                checked: true,
                message: "Warning - Scheduler enabled - system live - all emails are sent to clients!",
                color: "red"
            })
        } else {
            setSchedulerCheckbox({
                checked: false,
                message: "Scheduler disabled - only manual sending emails",
                color: "green"
            })
        }
    }

    const handleClose = () => {
        console.log()
    }

    function handleSubmitConfig(event) {
        event.preventDefault()
        let data = {
            'admin_email': event.target.admin_email.value,
            'sender_email': event.target.sender_email.value,
            'sender_user': event.target.sender_user.value,
            'sender_password': event.target.sender_password.value,
            'sender_name': event.target.sender_name.value,
            'sender_host': event.target.sender_host.value,
            'sender_port': event.target.sender_port.value,
            'client_url': event.target.client_url.value,
            'app_url': event.target.app_url.value,
            'proxy_email': event.target.proxy_email.value,
            'mailing_enabled': config.mailing_enabled,
            'scheduler_enabled': config.scheduler_enabled,
            'scheduler_timer': value.toString()
        }
        const url = '/api/config/update';
        fetch(url, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",

            },
            body: JSON.stringify(data),
        }).then(res => res.json()).then(data => {
            handleClose()
            window.location.reload();
        });
    }

    const handleOpenFile2 = () => {
        const fileURL = "/static/";
        fetch(fileURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/zip',
            },
        })
            .then((response) => {
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(
                    new Blob([response.data], {type: "application/octet-stream"})
                );
                link.download = "atd_backup.xlsx";
                document.body.appendChild(link);

                link.click();
                setTimeout(function () {
                    window.URL.revokeObjectURL(link);
                }, 200);
            })
            .catch((error) => {
            });
    }


    function handleSubmitFile(event) {
        event.preventDefault()
        const url = 'api/upload';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        axios.post(url, formData, config).then((response) => {
            console.log(response.data);
        });
    }


    const database = (event) => {
        setLoadingState(true)
        fetch('/api/db/' + event.target.value).then(res => res.json()).then(data => {
            setResult(data)
            console.log(data)
            setLoadingState(false)
        });
    }


    const handleDownload = (url, filename) => {
        axios.defaults.headers = {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        };
        console.log(url)
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
            })
    }
    const exportxls = (event) => {
        fetch('/api/db/' + event.target.value).then(res => res.json()).then(data => {
            if (data) {
                handleDownload('/downloads/db_backup.xlsx', 'dbase.xlsx')
                //handleDownload( 'http://192.168.0.240:3000/static/downloads/db_backup.xlsx', 'dbase.xls')
            }
        });

    }

    const go = (event, c) => {
        event.preventDefault();
        window.location.assign(event.target.value);
    };
    const getSelected = () => {
        setSelected = window.location.pathname.split('/')[0];
    }
    const Input = styled('input')({
        display: 'none',
    });


    const RenderTree = (props) => {
        if (typeof props.value === 'object') {
            if (props.value == undefined) {
            } else {
                {
                    Object.keys(props.value).map((item) => (
                        renderTree2(props.value[item], item)
                    ))
                }
            }
        } else {
            return (
                <div>{props.key}{props.value}</div>
            )
        }
    }
    const renderTree2 = (value, key) => {
        if (typeof value === 'object') {
            if (value == undefined) {
            } else {
                {
                    Object.keys(value).map((item) => (
                        renderTree2(value[item], item)
                    ))
                }
            }
        } else {
            return (
                <div>{key}{value}</div>
            )
        }
    }
    React.useEffect(() => {
        fetch('/api/config/setup')
            .then(response => response.json())
            .then(data => {
                setConfig(data)
                setValue(data.scheduler_timer)
                SetLiveMailing(data.mailing_enabled)
                SetLiveScheduler(data.scheduler_enabled)
            })
    }, []);
    const FormTxtItem = (item) => {
        return (
            <TextField
                type={item.type}
                margin="dense"
                id={item.formname}
                label={item.formname}
                fullWidth
                variant="standard"
                defaultValue={item.formvalue}
            />
        )
    }
    return (
        <Grid container>
                <Dialog open={loadingState}>
                    <DialogTitle>Loading</DialogTitle>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Box sx={{height: 90}}>

                            <CircularProgress/>
                        </Box>
                    </Box>
                </Dialog>
                <FormControl fullWidth>
                    <form onSubmit={handleSubmitConfig}>
                        <Stack sx={{textAlign: "right"}} spacing={0}>
                            <Stack xs={5}><FormTxtItem type="default" formvalue={config.admin_email}
                                                       formname="admin_email"/></Stack>
                            <Stack xs={5}><FormTxtItem type="default" formvalue={config.sender_name}
                                                       formname="sender_name"/></Stack>
                            <Stack xs={5}><FormTxtItem type="default" formvalue={config.sender_email}
                                                       formname="sender_email"/></Stack>
                            <Stack xs={5}><FormTxtItem type="default" formvalue={config.sender_user}
                                                       formname="sender_user"/></Stack>
                            <Stack xs={5}><FormTxtItem type="default" formvalue={config.sender_host}
                                                       formname="sender_host"/></Stack>
                            <Stack xs={5}><FormTxtItem type="default" formvalue={config.sender_port}
                                                       formname="sender_port"/></Stack>
                            <Stack xs={5}><FormTxtItem type="password" formvalue={config.sender_password}
                                                       formname="sender_password"/></Stack>
                            <Stack xs={5}><FormTxtItem type="default" formvalue={config.client_url}
                                                       formname="client_url"/></Stack>
                            <Stack xs={5}><FormTxtItem type="default" formvalue={config.app_url}
                                                       formname="app_url"/></Stack>
                            <Stack xs={5}><FormTxtItem type="default" formvalue={config.proxy_email}
                                                       formname="proxy_email"/></Stack>
                            <Stack direction="row" mt={2} mb={2}>
                                <Typography id="input-slider" align="left">
                                    Advance - weeks
                                </Typography>
                                <Slider
                                    aria-label="Volume"
                                    value={value}
                                    onChange={rangeChange}
                                    valueLabelDisplay="auto"
                                    getAriaValueText={valuetext}
                                    min={-4}
                                    max={3}
                                    marks={marks}
                                    track={false}
                                    color="warning"
                                /></Stack>
                            <Stack backgroundColor={mailingCheckbox.color} direction="row">&nbsp;
                                <FormControlLabel control={
                                    <Switch color="warning" checked={config.mailing_enabled} onChange={handleMailing}
                                            input_props={{'aria-label': 'controlled'}} name="proxy_enabled"/>
                                } label={mailingCheckbox.message}
                                />
                            </Stack>
                            <Stack backgroundColor={schedulerCheckbox.color} direction="row">&nbsp;
                                <FormControlLabel control={
                                    <Switch color="warning" checked={config.scheduler_enabled}
                                            onChange={handleScheduler}
                                            input_props={{'aria-label': 'controlled'}} name="scheduler_enabled"/>
                                } label={schedulerCheckbox.message}
                                />
                            </Stack>
                            <button type="submit">Save</button>
                        </Stack>
                    </form>
                </FormControl>
                <FormControl fullWidth>
                    <Typography xs={5}>Upload excel:</Typography>
                    <Divider/>
                    <form onSubmit={handleSubmitFile}>
                        <input type="file" onChange={handleChange}/>
                        <button type="submit">Upload</button>
                        <Button disabled={initReady} variant="contained" value="init" onClick={database}>Init
                            DB</Button>
                    </form>
                </FormControl>
                <Button variant="contained" value="backup" onClick={database}>Backup DB</Button>
                <Button variant="contained" value="export" onClick={exportxls}>Export DB</Button>

                <CTRL_DBChecker/>
                <RenderTree value={result}/>
                {Object.keys(result).map((item) =>
                    <div>
                        {Object.keys(result[item]).length ?
                            <Stack direction="row">{item}:&nbsp; {typeof result[item] === 'object'
                                ?
                                <div>
                                    {result[item].operation} {JSON.stringify(result[item].object)}
                                    {result[item].result ?
                                        " success"
                                        :
                                        " failed"
                                    }
                                </div>
                                :
                                result[item]}</Stack>
                            :
                            ""}
                    </div>
                )}

        </Grid>
    );
}