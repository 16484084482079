import React from 'react';
import {
    Button,
    Box,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Card,
    CardHeader,
    CardContent
} from '@mui/material';
import {ElementField, ElementMenuField, CategoryField, PurposeField} from '../../../Elements/Calculator/ELEM_Calculator';


const TF = ({name, label, value, onChange}) => (
    <TextField name={name} label={label} value={value} onChange={onChange}/>
);

const SL = ({name, label, value, onChange, options}) => (
    <FormControl>
        <InputLabel>{label}</InputLabel>
        <Select name={name} value={value} onChange={onChange}>
            {options.map((option, index) => (
                <MenuItem key={index} value={option}>
                    {option}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

class CTRL_CalcAddElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                uname: '',
                type: 'asset',
                attr: {
                    name: '',
                    result: null,
                    calc: 'Length',
                    unit: 'm',
                    price: 0,
                    optional: true,
                    active: false,
                    visible: true,
                    valid: [],
                },
            },
        };
        this.typeOptions = ['category', 'purpose', 'block', 'asset', 'assetmenu'];
    }

    handleAttrChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const attrName = name.split('.')[1]; // split the name string to get the actual attribute name
        let unit = this.state.formData.attr.unit;
        if (attrName === "calc") {
            switch (value.toLowerCase()) {
                case "length":
                    unit = "m"
                    break
                case "area":
                    unit = "m2"
                    break
                case "volume":
                    unit = "m3"
                    break
                default:
                    unit = "pcs"
                    break
            }
        }

        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                attr: {
                    ...prevState.formData.attr,
                    [attrName]: value,
                    unit: unit,
                },
            },
        }));
    };

    handleNonAttrChange = (event, value) => {
        if (event.target.name === 'type') {
            let newAttr;
            switch (event.target.value) {
                case 'asset':
                    newAttr = {
                        name: '',
                        result: null,
                        calc: 'Length',
                        unit: 'm',
                        price: 0,
                        optional: true,
                        active: false,
                        visible: true,
                        valid: [],
                    };
                    break;
                case 'category':
                    newAttr = {
                        name: '',
                        price: 0,
                        elements: []
                    };
                    break;
                case 'purpose':
                    newAttr = {name: '', price: 0};
                    break;
                default:
                    newAttr = this.state.formData.attr;
            }

            this.setState({
                formData: {
                    ...this.state.formData,
                    type: event.target.value,
                    attr: newAttr,
                },
            });
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [event.target.name]: event.target.value,
                },
            });
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
        fetch('/api/calcconfig/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.state.formData),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                this.setState({
                    formData: {
                        uname: '',
                        type: 'asset',
                        attr: {
                            name: '',
                            result: null,
                            calc: 'Length',
                            unit: 'm',
                            price: 0,
                            optional: true,
                            active: false,
                            visible: true,
                            valid: [],
                        },
                    },
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    renderAttrFields = () => {
        const {attr} = this.state.formData;
        switch (this.state.formData.type) {
            case 'element':
                return <ElementField attr={attr} onChange={this.handleAttrChange}/>;
            case 'elementmenu':
                return <ElementMenuField attr={attr} onChange={this.handleAttrChange}/>;
            case 'category':
                return <CategoryField attr={attr} onChange={this.handleAttrChange}/>;
            case 'purpose':
                return <PurposeField attr={attr} onChange={this.handleAttrChange}/>;
            default:
                return null;
        }
    }

    render() {
        return (
            <Box>
                <form onSubmit={this.handleSubmit}>
                    <Card
                        variant="outlined"
                        sx={{
                            margin: '20px',   // Margins around the card
                            borderColor: 'rgb(35,141,157)',  // Thicker darker outline
                            borderWidth: '2px',  // Set the outline thickness
                        }}
                    >
                        <CardHeader title="Add Item"/>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <SL
                                        name="type"
                                        label="Type"
                                        value={this.state.formData.type}
                                        onChange={this.handleNonAttrChange}
                                        options={this.typeOptions}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="uname"
                                        label="ID"
                                        value={this.state.formData.uname}
                                        onChange={this.handleNonAttrChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {this.renderAttrFields()}
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" type="submit">
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </form>
            </Box>
        )
            ;
    }
}

export default CTRL_CalcAddElement;
